import { useMutation } from '@apollo/client';
import { IlOut } from '@mc/react-icons/il';
import { MenuItem, MenuList, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { authSliceActions, getAccountId } from 'processes/auth';
import { getIsLineActive } from 'processes/line';
import {
  getAccountName,
  getAccountRole,
  getOwner,
  getRolePermissions,
} from 'processes/user';

import { LOGOUT } from 'shared/api/authApi';
import { PermissionType } from 'shared/api/authApi/types';
import { LoginRoutePaths } from 'shared/const/router';
import {
  getRouteUserDetailsInfo,
  getRouteUserDetailsSettings,
} from 'shared/const/router/users/users.paths';
import { nameFormat } from 'shared/lib/utils/nameFormat';
import { getBrowserStorage } from 'shared/lib/utils/storage';
import { Button } from 'shared/ui/buttons/Button';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import cls from './NavbarAccountControl.module.scss';

const needSubscriptionRules = [
  PermissionType['SUBSCRIPTIONS.CREATE_ALL'],
  PermissionType['SUBSCRIPTIONS.DELETE'],
  PermissionType['SUBSCRIPTIONS.DELETE_ALL'],
  PermissionType['SUBSCRIPTIONS.DISABLE_ALL'],
  PermissionType['SUBSCRIPTIONS.ENABLE_ALL'],
  // PermissionType['SUBSCRIPTIONS.GET_LIST'],
  // PermissionType['SUBSCRIPTIONS.GET_LIST_ALL'],
  PermissionType['SUBSCRIPTIONS.UPDATE'],
  PermissionType['SUBSCRIPTIONS.UPDATE_ALL'],
];

export const AccountDropdown = () => {
  const dispatch = useDispatch();
  const accountName = useSelector(getAccountName);
  const personalData = nameFormat(accountName.name, accountName.surname);

  const accOwner = useSelector(getOwner);
  const accRole = useSelector(getAccountRole);
  const isLineActive = useSelector(getIsLineActive);

  const navigate = useNavigate();
  const activeAccountId = useSelector(getAccountId);

  const navigateToUserDetailsInfo = () => {
    if (activeAccountId) navigate(getRouteUserDetailsInfo(activeAccountId));
  };
  const navigateToUserSettings = () => {
    if (activeAccountId) navigate(getRouteUserDetailsSettings(activeAccountId));
  };

  const userPermissions = useSelector(getRolePermissions);

  const hasPermissionToMutateSubscriptions = useMemo(() => {
    return needSubscriptionRules.some((el) => {
      const hasPermission = userPermissions.has(el);
      return hasPermission;
    });
  }, [userPermissions]);

  // Мутация logout
  const [logout] = useMutation(LOGOUT);

  const handleOnExit = useCallback(async () => {
    const refreshToken = getBrowserStorage('REFRESH_TOKEN_KEY');

    if (refreshToken) {
      try {
        logout({ variables: { input: { refreshToken } } });
      } catch (err) {
        console.error(err);
      }
    }

    setTimeout(() => {
      dispatch(authSliceActions.clearAccountData());
      window.location.href = LoginRoutePaths.getRouteLogin();
    }, 1000);
  }, [dispatch, logout]);

  return (
    <Stack className={cls.DropdownBody}>
      <Stack className={cls.DropdownHeader} gap='4px'>
        <Typography variant='h3' noWrap onClick={navigateToUserDetailsInfo}>
          {personalData}
        </Typography>

        <Stack gap='2px'>
          <Typography
            variant='t2'
            noWrap
            color='var(--color-monochrome-grey-400)'
          >
            &quot;{accOwner.name}&quot;
          </Typography>

          <Typography
            variant='t2'
            noWrap
            color='var(--color-monochrome-grey-400)'
          >
            {accRole.name}
          </Typography>
        </Stack>
      </Stack>

      {hasPermissionToMutateSubscriptions && (
        <Stack className={cls.DropdownMain}>
          <MenuList>
            <MenuItem className={cls.MenuItem} onClick={navigateToUserSettings}>
              <Typography variant='t2'>Настройка уведомлений</Typography>
            </MenuItem>

            {/* TODO: сделать переключение темной и светлой темы - есть в дизайне, но пока не работает */}
            {/* <MenuItem classes={cls.MenuItem}>
            <Typography variant='t2'>Установить темную тему</Typography>
          </MenuItem> */}
          </MenuList>
        </Stack>
      )}

      <Stack
        className={cls.DropdownFooter}
        flexDirection='row'
        justifyContent='end'
      >
        <Stack className={cls.ButtonContainer}>
          <Button
            size='small'
            variant='outlined'
            justifyContent='space-between'
            onClick={handleOnExit}
            endIcon={
              <IconWrapper
                Svg={IlOut}
                style={{ width: '18px', height: '18px' }}
              />
            }
            disabled={isLineActive}
          >
            Выйти
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
